import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Helmet from 'react-helmet'
import WhoWeArePageHeroSr from '../components/WhoWeArePageHeroComponentSr'

import WhoWeAreTeamSr from '../components/WhoWeAreTeamComponentSr'
import NavbarSr from '../components/Navbar'
import FooterSr from '../components/FooterSr'

import Favicon from '../favicon-32x32.png'

import ogMeta from '../scss/images/_02_1.jpg'

export const PageTemplate = ({
  title,
  content,
  aboutUsData,
  aboutUsDataTeamAcf,
  homeElementsData,
}) => (
  <section className="section section--gradient">
    <WhoWeArePageHeroSr
      whoWeAreSectionOne={
        aboutUsData.homepageWhoWeAreSection.whowearesectionone
      }
      whoWeAreSectionTwo={
        aboutUsData.homepageWhoWeAreSection.whowearesectiontwo
      }
    />

    <WhoWeAreTeamSr
      enteringAcfData={aboutUsData}
      enteringAcfTeam={aboutUsData}
    />
  </section>
)

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  aboutUsData: PropTypes.object,
  aboutUsDataTeamAcf: PropTypes.object,
  homeElementsData: PropTypes.object,
}

const Page = ({ data }) => {
  const { wpPage: page } = data
  return (
    <div>
      {/* <Helmet title={`${page.title} | Interior Design Studio`} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${page.title} | Interior Design Studio`}</title>
        <link rel="canonical" href={`http://ananenadovic.com/${page.slug}`} />

        <meta name="description" content={`${page.content}`} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
        <meta
          property="og:title"
          content={`${page.title} | Interior Design Studio`}
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://ananenadovic.com/${page.slug}`}
        />
        <meta name="robots" content="index" />
        <meta property="og:image" content={ogMeta} />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`${page.title} | Interior Design Studio`}
        />
        <meta name="twitter:description" content={`${page.content}`} />
        <meta name="twitter:image" content={ogMeta} />
      </Helmet>
      <NavbarSr />
      <PageTemplate
        title={page.title}
        content={page.content}
        aboutUsData={data.aboutUs}
        aboutUsDataTeamAcf={data.aboutUs.acfTeam}
        homeElementsData={data.homeElements}
      />
      <div className="stickTheFooter">
        <FooterSr />
      </div>
    </div>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageByIdSr($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
    aboutUs: wpPage(
      template: { templateName: { eq: "About Us" } }
      locale: { locale: { eq: "sr_RS" } }
    ) {
      id
      title
      content
      homepageWhoWeAreSection {
        whowearesectionone
        whowearesectiontwo
      }
      acfTeam {
        teammembers {
          teammembertext
          teammemberimage {
            mediaItemUrl
			localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
            mediaDetails {
              height
              width
              file
            }
          }
        }
      }
    }
  }
`
