import React from 'react'

import AniLink from 'gatsby-plugin-transition-link/AniLink'
import allSvgLink from '../img/images.svg'

// const gayArrow = `<use xlink:href=${allSvgLink}#gay_arrow_right_1 />`
// const faceSvg = `<use xlink:href=${allSvgLink}#icon_facebook_small />`
const instagramSvg = `<use xlink:href=${allSvgLink}#icon_instagram_small />`
// const twitterSvg = `<use xlink:href=${allSvgLink}#icon_twitter_small />`

// const LocationOne = ({ props }) => ()
// const LocationOne = ({ props }) => ()

class FooterSr extends React.Component {
  render() {
    return (
      <div className="footerHolder footer">
        <footer>
          <div className="footer_holder">
            <div className="workingHoursInfo">
              <div className="workingHours">
                <p>Ponedeljak - Petak</p>
                <p>9h - 17h</p>
              </div>
              <div className="adress_div">
                <p>Dobračina 43 / Beograd</p>
              </div>
              <div className="phone_and_email_div">
			  <div className="email_div">
					<a className="catButton" href="mailto:info@ananenadovic.com">
					info@ananenadovic.com
					</a>
				  </div>
				  <div className="phone_div">
					<a className="catButton" href="tel:+38169 063 0620">
					+38169 063 0620
					</a>
				  </div>
              </div>
            </div>

            <div className="footerSocialIcons">
              {/* <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="faceSvg"
                  dangerouslySetInnerHTML={{ __html: faceSvg }}
                />
              </a> */}
              <a
                href="https://www.instagram.com/ana__nenadovic"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="instagramSvg"
                  dangerouslySetInnerHTML={{ __html: instagramSvg }}
                />
              </a>
              {/* <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="twitterSvg"
                  dangerouslySetInnerHTML={{ __html: twitterSvg }}
                />
              </a> */}
            </div>
            <div className="footerRightMenu">
              <ul>
                <li>
                  <AniLink
                    fade
                    className="catButton footerBtn"
                    direction="up"
                    bg="#ffffff"
                    duration={1.3}
                    to="/sr/"
                  >
                    Početna
                  </AniLink>
                </li>
                <li>
                  <AniLink
                    fade
                    className="catButton footerBtn"
                    direction="up"
                    bg="#ffffff"
                    duration={1.3}
                    to="/sr/projects/"
                  >
                    Projekti
                  </AniLink>
                </li>
                <li>
                  <AniLink
                    fade
                    className="catButton footerBtn"
                    direction="up"
                    bg="#ffffff"
                    duration={1.3}
                    to="/sr/about-us/"
                  >
                    O nama
                  </AniLink>
                </li>
                <li>
                  <AniLink
                    fade
                    className="catButton footerBtn"
                    direction="up"
                    bg="#ffffff"
                    duration={1.3}
                    to="/sr/inquiry/"
                  >
                    Upit
                  </AniLink>
                </li>
                <li>
                  <AniLink
                    fade
                    className="catButton footerBtn"
                    direction="up"
                    bg="#ffffff"
                    duration={1.3}
                    to="/"
                  >
                    En
                  </AniLink>
                  <AniLink
                    fade
                    className="catButton footerBtn"
                    direction="up"
                    bg="#ffffff"
                    duration={1.3}
                    to="/sr/"
                  >
                    Sr
                  </AniLink>
                </li>
              </ul>
            </div>
            <div className="footerCopyright">
              Designed and Developed by{' '}
              <a
                className="toSm catButton"
                target="_blank"
                href="https://singularminds.eu/"
              >
                @singularminds
              </a>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default FooterSr
