import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { useAboutUsDataForTeam } from '../hooks/AboutUsTeamDataQuery'
import anaNenadovicBw from '../img/anaBW.jpg'
import miljanImg from '../img/miljan-okuka-1.jpeg'
import sofijaImg from '../img/sofija-djukic-2.jpeg'

class WhoWeAreTeamSr extends Component {
  render() {
    const { enteringAcfData, enteringAcfTeam } = this.props

    let mediaimage = ''
    return (
      <div className="aboutUsContentHolder">
        <div className="aboutUsTitle">
          <h1>NAŠ TIM</h1>
        </div>

        <div className="aboutUsMemberWrapper">
          {enteringAcfData.acfTeam.teammembers.map((member, i) => {
            if (member.teammemberimage != null) {

              return (
                <div key={i + 1} className="aboutUsMemberHolder">
                  <div className="memberImageHolder">
                    <img
                      className="memberImage"
                      src={`${member.teammemberimage.localFile.childImageSharp.original.src}`}
                      alt=""
                    />
                    <div
                      className={i === 0 ? 'horizontalLine' : 'verticalLine'}
                    />
                  </div>
                  <div className="memberText">{member.teammembertext}</div>
                </div>
              )
            } else if (member.teammembertext == 'Sofija Djukic - Arhitekta') {

              return (
                <div key={i + 1} className="aboutUsMemberHolder">
                  <div className="memberImageHolder">
                    <img className="memberImage" src={sofijaImg} alt="" />
                    <div
                      className={i === 0 ? 'horizontalLine' : 'verticalLine'}
                    />
                  </div>
                  <div className="memberText">{member.teammembertext}</div>
                </div>
              )
            } else if (member.teammembertext == 'Miljan Okuka - Inž. arh.')
              return (
                <div key={i + 1} className="aboutUsMemberHolder">
                  <div className="memberImageHolder">
                    <img className="memberImage" src={miljanImg} alt="" />
                    <div
                      className={i === 0 ? 'horizontalLine' : 'verticalLine'}
                    />
                  </div>
                  <div className="memberText">{member.teammembertext}</div>
                </div>
              )
          })}
        </div>
      </div>
    )
  }
}

WhoWeAreTeamSr.propTypes = {
  enteringAcfData: PropTypes.object,
  enteringAcfTeam: PropTypes.object,
}
export default WhoWeAreTeamSr
